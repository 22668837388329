<template>
  <div>
    <header-slot></header-slot>
    <div class="px-2 mt-1">
      <b-nav card-header pills class="m-0">
        <b-nav-item
          :to="{ name: nameModule + '-claim-inquiry-pending' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          PENDING
          <span
            v-if="totalPendingInquiries > 0 && nameModule === 'specialists'"
            class="ml-1"
          >
            <feather-icon
              icon
              :badge="
                totalPendingInquiries > 99 ? '99+' : totalPendingInquiries
              "
              badge-classes="badge-danger"
            />
          </span>
        </b-nav-item>
        <b-nav-item
          :to="{ name: nameModule + '-claim-inquiry-solved' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          SOLVED
          <span
            v-if="totalSolvedInquiries > 0 && nameModule === 'quality'"
            class="ml-1"
          >
            <feather-icon
              icon
              :badge="totalSolvedInquiries > 99 ? '99+' : totalSolvedInquiries"
              badge-classes="badge-danger"
            />
          </span>
        </b-nav-item>
      </b-nav>
      <b-card
        no-body
        class="border-top-primary border-3 border-table-radius px-0"
      >
        <router-view />
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "InquiryClaim",
  components: {},
  data() {
    return {
      showClaimModal: false,
    };
  },
  computed: {
    nameModule() {
      return this.$route.meta.route;
    },
    ...mapGetters({
      totalPendingInquiries: "ClaimsStore/G_PENDING_INQUIRIES",
      totalSolvedInquiries: "ClaimsStore/G_SOLVED_INQUIRIES",
    }),
  },
  methods: {
    ...mapActions({
      A_PENDING_INQUIRIES: "ClaimsStore/A_PENDING_INQUIRIES",
    }),
  },
  async created() {
    await this.A_PENDING_INQUIRIES();
  },
};
</script>